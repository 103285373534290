import './App.css';
import AppBar from './components/AppBar';
import Homepage from './pages';
import About from './pages/about'
import Shippers from './pages/shippers';
import Footer from './components/Footer';
import Contact from './pages/contact';
import LoginPage from './pages/login';


import MobileContext from './context/MobileProvider';
import { useMediaQuery } from '@mui/material';

import { Route, Routes, useLocation } from 'react-router-dom';

import { useState, useEffect } from 'react';

function App() {
  const isMobileMode = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const location = useLocation();

  const requestDeviceList = async () => {
    try {
      const devices = await navigator.bluetooth.requestDevice({ acceptAllDevices: true, optionalServices: ['06caf9c0-74d3-454f-9be9-e30cd999c17a'] });
      console.log(devices);

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <MobileContext.Provider value={isMobileMode}>
      <AppBar mode='light' />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<Homepage />} />
        <Route path="/shippers" element={<Homepage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
      {location.pathname === '/login' ? '' : <Footer />}
    </MobileContext.Provider>
  );
}

export default App;
